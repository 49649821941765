import { Doughnut, mixins } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default {
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],
  methods: {
    // print () {
    //     // grab the canvas and generate an image
    //     const image = this.$refs.canvas.toDataURL("image/png");
    //     // Emits an event with the image
    //     this.$emit("chart:print", image);
    // },
    // handle (point, event) {
    //     // eslint-disable-next-line no-console
    //     const item = event[0];
    //     this.$emit("on-receive", {
    //         index: item._index,
    //         backgroundColor: item._view.backgroundColor,
    //         value: this.chartData.datasets[0].data[item._index]
    //     });
    // }
  },
  mounted () {
    this.addPlugin(ChartDataLabels)
    this.renderChart(
      this.chartData,
      this.options
    )
  }
}
