<template>
    <div class="w3-theme-l1 w3-container" style="padding-top: 16px;padding-bottom:16px">
		<div class="w3-row w3-white w3-padding">
			<div class="w3-col l12 m12 s12 w3-row">
				<div class="w3-col l3 s4 m2">
					<select v-on:change="loadGrafik()" class="w3-select w3-small w3-white w3-border" v-model="periode.bln">
						<option value="1">Januari</option>
						<option value="2">Februari</option>
						<option value="3">Maret</option>
						<option value="4">April</option>
						<option value="5">Mei</option>
						<option value="6">Juni</option>
						<option value="7">Juli</option>
						<option value="8">Agustus</option>
						<option value="9">September</option>
						<option value="10">Oktober</option>
						<option value="11">November</option>
						<option value="12">Desember</option>
					</select>
				</div>
				<div class="w3-col l2 s2 m2">
					<select v-on:change="loadGrafik()" class="w3-select w3-small w3-white w3-border" v-model="periode.thn">
						<option v-for="dtl in [2021,2022,2023,2024,2025]" :value="dtl" :key="dtl">{{dtl}}</option>
					</select>
				</div>
			</div>
			<div class="w3-col l7 m6 s12">
				<DeLoading :show="!recGrafikLines.show" ></DeLoading>
				<div v-if="recGrafikLines.show">
					<deLine :chartData="recGrafikLines.grafik" :options="recGrafikLines.options" :height="370"></deLine>
				</div>
			</div>
			<div class="w3-col l5 m6 s12">
				<DeLoading :show="!grafikDonut.show" ></DeLoading>
				<deDoughnut v-if="grafikDonut.show" :height="370"
					:chartData="grafikDonut.chartData"
					:options="grafikDonut.chartOptions">
				</deDoughnut>
			</div>
		</div>
		<div class="w3-card-4 w3-padding w3-white w3-margin-top">
			<div class="w3-row-padding">
				<div class="w3-col l1">
					Online :
				</div>
				<div class="w3-col l11">
					<input type="text" style="text-align: right;" v-model="recSetting.setting_online" />
				</div>
			</div>
			<div class="w3-row-padding w3-margin-top">
				<div class="w3-col l1">
					Ofline :
				</div>
				<div class="w3-col l11">
					<input type="text" style="text-align: right;" v-model="recSetting.setting_ofline" />
				</div>
			</div>
			<button @click="saveData()" type="button" class="w3-btn w3-blue"><i class="fa fa-save"></i>Save</button>
		</div>
		
		<div class="w3-card-4 w3-padding w3-white w3-margin-top">
			<button @click="UploadFile()" class="w3-btn w3-theme" type="button"><i class="fa fa-plus"></i> Add Video</button>
			<div class="w3-row w3-margin-top">
				<div class="w3-col l2" v-for="(item,index) in recVideos" style="padding: 5px;">
					<div class="w3-card" >
						<video :src="item.url" class="w3-image" id="video-container" style="background-color: black; height: 200px;" width="100%" controls></video>
						<button @click="deleteFile(item,index)" type="button" class="w3-block"><i class="fa fa-trash-o"></i> Delete</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/**Tools */
import deLine from "@/charts/LineChart.js";
import deDoughnut from "@/charts/Doughnut.js";
import moment from "moment";
export default {
    head: {
        title: {
          inner: 'Main'
        }
    },
    name : 'Main',
    components: {
        deDoughnut, deLine
    },
    data() {
        return {
            screenHeight : 0,
            recSetting: {},
			recVideos:[],
			periode: {bln : moment(String(new Date())).format("M"), thn : moment(String(new Date())).format("YYYY")},
			colors : ["#ec751c", "#01579b"],
			recGrafikLines: {
			    show:false,
			    grafik: {},
			    options: {
			        responsive: true,
			        maintainAspectRatio: false,
			        title: {
			            display: true,
			            text: ""
			        },
					plugins: {
						datalabels: {
							color: "black",
							display: function (context) {
								const dataset = context.dataset;
								const value = dataset.data[context.dataIndex];
								return value > 0;
							},
							font: {
								weight: "bold",
								size: "11"
							},
							padding: 6,
							formatter: (value) => {
								return `${Intl.NumberFormat("de-DE").format(value)}`;
							},
							labels: {
								title: {
									font: {
									weight: 'bold'
									}
								},
								value: {
									color: 'green'
								}
							}
						}
					},
			        layout: {
			            padding: {
			                left: 20,
			                right: 30,
			                top: 5,
			                bottom: 20
			            }
			        }
			    }
			},
			grafikDonut: {
				show: false,
				chartOptions: {
					legend: {
						position: "top"
					},
					title: {
						display: true,
						text: ""// text: "Pendapatan Bulan " + moment(String(new Date())).format("M") + " Tahun " + moment(String(new Date())).format("YYYY")
					},
					hoverBorderWidth: 10,
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						datalabels: {
							backgroundColor: function (context) {
								return context.dataset.backgroundColor;
							},
							borderColor: "white",
							borderRadius: 25,
							borderWidth: 2,
							color: "white",
							display: function (context) {
								const dataset = context.dataset;
								const value = dataset.data[context.dataIndex];
								return value > 0;
							},
							font: {
								weight: "bold",
								size: "11"
							},
							padding: 6,
							// formatter: Math.round
							// eslint-disable-next-line no-unused-vars
							formatter: (value, ctx) => {
								let sum = 0;
								const dataArr = ctx.chart.data.datasets[0].data;
								dataArr.map(data => {
									sum += data;
								});
								// eslint-disable-next-line new-cap
								const percentage = (value * 100 / sum).toFixed() + `% (${Intl.NumberFormat("de-DE").format(value)})`;
								return percentage;
							}
						}
					},
					aspectRatio: 4 / 3,
					cutoutPercentage: 32,
					layout: {
						padding: {
							left: 20,
							right: 20,
							top: 5,
							bottom: 20
						}
					},
					elements: {
						line: {
							fill: false
						},
						point: {
							hoverRadius: 7,
							radius: 5
						}
					}
				},
				chartData: {
					hoverBorderWidth: 2,
					borderColor: "#000",
					kode: [],
					labels: [],
					datasets: [
						{
							anchor: "center",
							borderWidth: 0,
							backgroundColor: ["#ec751c", "#01579b", "#b72214", "#e6301f", "#f29189", "#fbdad8", "#cddf68", "#8cb1df", "#316cb3", "#647a95", "#eca54d"],
							data: [],
							datalabels: {
								anchor: "end"
							}
						}
					]
				}
			},
        }
    },
    methods : {
        loadSetting() {
			const self = this;
			self.apiPost("/antrian/setting/search",{
				operator: "AND",
				kondisi: "",
				field: "",
				fieldvalue: "",
				limit: 1,
				offset: 0,
			},function(res){
				self.recSetting = res.rec[0];
			},function(res){console.log(err);})
		},
		loadVideo() {
			const self = this;
			self.apiPost("/antrian/iklan/search",{
				operator: "AND",
				kondisi: "",
				field: "",
				fieldvalue: "",
				limit: 100,
				offset: 0,
			},function(res){
				res.rec.map(function(val){
					val['url'] = self.apiUrl+ '/gambar/' + val['iklan_file'];
				});
				self.recVideos = res.rec;
			},function(res){console.log(err);})
		},
		saveData(){
			const self = this;
			self.recSetting["setting_code_old"] = self.recSetting["setting_code"];
			self.apiPost('/antrian/setting/edit', self.recSetting, function(res){
				self.$toast.success("Data sudah di update")
			}, function(err){console.log(err);})
		},
		selectFile(contentType, multiple) {
			return new Promise(resolve => {
				let input = document.createElement("input");
				input.type = "file";
				input.multiple = multiple;
				input.accept = contentType;
				// eslint-disable-next-line no-unused-vars
				input.onchange = _ => {
					let files = Array.from(input.files);
					if (multiple) {
						resolve(files);
					} else {
						resolve(files.first);
					}
				};
				input.click();
			});
		},
		async UploadFile(){
			let self = this;
			let files = await self.selectFile("video/mp4", true);
			self.showLoading("Start Upload");			
			let vparams =[{key : "folder","val": "iklan"}];
			let dt = await self.deUploadFile("/tproject/admin/upload", files[0] ,vparams, self);
			console.log(dt);
			// dtl["pelamar_lowongan_sarat_file"] = dt.data.nama_file;
			self.apiPost("/antrian/iklan/save", {
				iklan_code_old: "",
				iklan_code: "",
				iklan_file: dt.data.nama_file,
				iklan_keterangan: "",
				iklan_status: "T"
			}, function(res){
				self.hideLoading();
				self.loadVideo();
			}, function(err){
				self.hideLoading();
			});
			
		},
		async deleteFile(dtl, index){
			const self = this;
			swal({
			    title: "Yakin Ingin Menghapus Data ..!",
			    text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
			    type: "warning",
			    showCancelButton: true,
			    confirmButtonColor: "#ff0000",
			    confirmButtonText: "Ya, Hapus Data",
			    cancelButtonText: "Tidak, Batal Hapus",
			    closeOnConfirm: true,
			    closeOnCancel: true
			},function(isConfirm){
			    if (isConfirm) {
					self.apiPost("/tproject/admin/deletefile", {"file": dtl.iklan_file}, function(res){
						self.apiPost("/antrian/iklan/delete", {"iklan_code_old": dtl.iklan_code}, function(resd){
							self.$delete(self.recVideos,index);
							self.$toast.success('Data Sudah terhapus');
						}, function(errd){});
					}, function(err){});     
			    }
			});
		},
		async loadLine () {
			const self = this;
			self.recGrafikLines.show = false;
			self.recGrafikLines.options.title.text = "Grafik Antrian";
			let dtgrafik = { labels: [], datasets: [], val: [] };
			let responsehari = await self.apiAsync("/antrian/antrian/search", {
				operator: "AND",
				kondisi: "=;=;=",
				field: "date_part('MONTH',antrian_date);date_part('YEAR',antrian_date)",
				fieldvalue: `${self.periode.bln};${self.periode.thn}`,
				limit: 100,
				offset: 0,
				fieldselect: "date_part('DAY',antrian_date) as hari, count(*) as total",
				group: "hari",
				order: "hari"
			});
			if (responsehari.success === true) {
				for (let i = 0; i < responsehari.rec.length; i++) {
					dtgrafik.labels.push(responsehari.rec[i]["hari"]);
					dtgrafik.val.push(responsehari.rec[i]["total"]);
				}
				dtgrafik.datasets.push({
					label: "Pasien",
					backgroundColor: "transparent",
					borderColor: self.colors[0],
					data: dtgrafik.val,
					fill: true,
					tension: 0.4
				});
				setTimeout(function () {
					console.log(dtgrafik);
					self.recGrafikLines.grafik = dtgrafik;
					self.recGrafikLines.show = true;
				}, 1000);
			}
		},
		async loadDonut() {
			const self = this;
			self.grafikDonut.show = false; 
			self.apiPost("/antrian/antrian/search", {
				operator: "AND",
				kondisi: "=;=",
				field: "date_part('MONTH',antrian_date);date_part('YEAR',antrian_date)",
				fieldvalue: `${self.periode.bln};${self.periode.thn}`,
				limit: 100,
				offset: 0,
				fieldselect: "pelayanan.pelayanan_code, pelayanan.pelayanan_name, count(*) as total",
				group: "pelayanan.pelayanan_code, pelayanan.pelayanan_name",
				order: "pelayanan.pelayanan_name, pelayanan.pelayanan_code"
			}, function (response) {
				// eslint-disable-next-line no-console
				console.log(response);
				if (response.success === true) {
					self.grafikDonut.chartData.labels = [];
					self.grafikDonut.chartData.datasets[0].data = [];
					let dtgrafik = { labels: [], data: [] };
					for (let i = 0; i < response.rec.length; i++) {
						dtgrafik.labels.push(response.rec[i]["pelayanan_name"]);
						dtgrafik.data.push(parseFloat(response.rec[i]["total"]));
					}
					setTimeout(function () {
						self.grafikDonut.chartData.labels = [].concat(dtgrafik.labels);
						self.grafikDonut.chartData.datasets[0].data = [].concat(dtgrafik.data);
						self.grafikDonut.show = true;
					}, 500);
				} else {
					// eslint-disable-next-line no-console
					console.log("Gagal");
				}
			});
		},
		loadGrafik() {
			this.loadLine();
			this.loadDonut();
		}
    },
    mounted(){
        this.screenHeight = window.innerHeight;
		this.loadSetting();
		this.loadVideo();
		this.loadGrafik();
    }
};
</script>
